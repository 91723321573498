<template>
  <div v-if="item" class="container">
    <h3 class="text-center my-4">
      <span style="font-weight: bold;">{{ item.course.title }}</span>
      <span class="d-block mt-1">
        {{ userData.school.name }}
      </span>
    </h3>

    <b-card no-body class="mb-1">
      <b-card-header>
        <b-card-title class="mb-1">
          {{ item.name }} - {{ item.modality.title }}
        </b-card-title>
        <b-card-sub-title style="margin-top: 6px; color: #707070 !important;">
          Dias: <span v-for="(day, index) in item.weekdays" :key="`day-${index}`">
            {{ day.title.replace('-Feira', '') }}
            <span v-if="index !== item.weekdays.length - 1">-</span>
          </span>
        </b-card-sub-title>
      </b-card-header>

      <b-card-body class="pl-2 pr-2">
        <b-card-text>
          <div class="d-flex" style="column-gap: 30px;">
            <div v-if="item.unit" class="">
              <strong>Unidade: </strong> {{ item.unit.title }}
            </div>

            <div class="">
              <strong>Turno: </strong> {{ item.shift.title }}
            </div>

            <div class="">
              <strong>Início: </strong> {{ item.postponements[0].date | datePT() }}
            </div>

            <div class="">
              <strong>Término: </strong> {{ item.end_date }}
            </div>

            <div class="">
              <strong>Horário: </strong> {{ item.start_time }} às {{ item.end_time }}
            </div>
          </div>
        </b-card-text>

        <table v-if="daysWork.length" class="table b-table table-days">
          <thead>
            <tr>
              <th v-for="(dayName, index) in nameWorkDays" :key="`header-${index}`" class="text-center">
                {{ dayName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(week, weekIndex) in daysWork" :key="`week-${weekIndex}`">
              <td v-for="(day, dayIndex) in week" :key="`day-${dayIndex}`">
                <div class="d-cols" style="margin: 2px 0px">
                  <span :class="day.titles.length ? 'bg-success' : 'bg-danger'" class="text-white fist-col" style="position: relative;">
                    {{ formatDateUSA(day.date, 'DD/MM') }}
                    <div v-if="day.titles.length > 1" class="absolute bg-primary" style="border-radius: 30%; width: 17px; height: 17px; display: flex; align-content: center; align-items: center; justify-content: center; top: 10px; position: absolute; right: -19px;">
                      {{ day.titles.length }}
                    </div>
                  </span>
                  <div class="last-col">
                    <strong v-if="day.titles.length" class="text-success">
                      <span v-for="(lesson, c) in day.titles" :key="`le-${c}`" style="width: 100%; display: block;">
                        {{ lesson }}
                      </span>
                    </strong>
                    <strong v-else class="text-danger">
                      [Sem aula]
                    </strong>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card-body>
    </b-card>

    <b-card no-body class="mb-1" v-if="weekendLessons.length">
      <b-card-header>
        <b-card-title class="mb-0">
          Aulas aos finais de semana
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <table class="table b-table table-days">
          <thead>
            <tr>
              <th v-for="(dayName, index) in nameWeekendDays" :key="`header-${index}`" class="text-center">
                {{ dayName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(week, weekIndex) in weekendLessons" :key="`week-${weekIndex}`">
              <td v-for="(day, dayIndex) in week" :key="`day-${dayIndex}`">
                <div class="d-cols" style="margin: 2px 0px">
                  <span :class="day.titles.length ? 'bg-success' : 'bg-danger'" class="text-white fist-col" style="position: relative;">
                    {{ formatDateUSA(day.date, 'DD/MM') }}
                    <div class="absolute bg-primary" style="border-radius: 30%; width: 17px; height: 17px; display: flex; align-content: center; align-items: center; justify-content: center; top: 10px; position: absolute; right: -19px;">
                      {{ day.titles.length }}
                    </div>
                  </span>
                  <div class="last-col">
                    <strong v-if="day.titles.length" class="text-success">
                      <span v-for="(lesson, c) in day.titles" :key="`le-${c}`" style="width: 100%; display: block;">
                        {{ lesson }}
                      </span>
                    </strong>
                    <strong v-else class="text-danger">
                      [Sem aula]
                    </strong>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>






      </b-card-body>
    </b-card>

    <b-card no-body class="mb-4">
      <b-card-header>
        <b-card-title class="mb-0">
          Disciplinas e Professores
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <table class="table b-table">
          <thead>
            <tr>
              <th v-for="(field, index) in fieldsDisciplines" :class="field.tdClass" class="table-b-table-default"
                style="padding: 10px !important;" :key="`weekf-${index}`">
                {{ field.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in item.disciplines">
              <td>
                {{ row.title }}
              </td>
              <td>
                <span v-if="row.teachers.length">
                  <span v-for="(teacher, index) in row.teachers" :key="`teacher-${index}`">
                    {{ teacher.name }}
                  </span>
                </span>
                <span v-else class="text-danger">Nenhum</span>
              </td>
              <td class="text-center">
                <strong>
                  {{ row.workload }}h
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import 'moment/locale/pt-br';

import {
  BListGroup,
  BListGroupItem,
  BTable,
  BCard,
  BCardText,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from 'bootstrap-vue'
import moment from 'moment';

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BTable,
    BCard,
    BCardSubTitle,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody
  },
  data() {
    return {
      userData: '',
      tableData: [],
      classUuid: '',
      item: '',
      fieldsDisciplines: [
        {
          label: "Disciplina",
          tdClass: "text-left font-weight-bold px-2",
        },
        {
          label: "Professor(s)",
          tdClass: "text-left font-weight-bold px-2",
        },
        {
          label: "Horas",
          tdClass: "text-center font-weight-bold px-2",
        },
      ],
      lessons: [],
      date_start: "",
      date_end: "",
      workingDays: [],
      nameWorkDays: ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira'],
      daysWork: [],
      weekendLessons: [],
      nameWeekendDays: ['Sábado', 'Domingo'],
      chunkedWeekendLessons: []
    }
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"))

    this.classUuid = this.$route.params.uuid;
    this.getData()
  },

  methods: {
    getWeekendLessons() {
      const allWeekendDays = [];

      // Determina o primeiro e o último dia de aula
      const firstLessonDate = moment(this.item.lessons[0].date, 'YYYY-MM-DD');
      const lastLessonDate = moment(this.item.lessons[this.item.lessons.length - 1].date, 'YYYY-MM-DD');

      // Determina o primeiro dia da semana para verificação
      const startOfWeek = firstLessonDate.clone().startOf('week').add(6, 'days'); // Último sábado antes do início

      // Adiciona um dia vazio antes se o primeiro dia de aula for um domingo
      if (firstLessonDate.day() === 0) {
        allWeekendDays.push({
          date: startOfWeek.format('YYYY-MM-DD'),
          titles: []
        });
      }

      // Itera sobre o período do primeiro ao último dia de aula
      const currentDate = startOfWeek.clone();

      while (currentDate.isSameOrBefore(lastLessonDate)) {
        const dayOfWeek = currentDate.day();

        // Verifica se o dia atual é sábado (6) ou domingo (0)
        if (dayOfWeek === 6 || dayOfWeek === 0) {
          // Filtra as aulas para o dia atual
          const lessonsForCurrentDate = this.item.lessons.filter(lesson =>
            moment(lesson.date, 'YYYY-MM-DD').isSame(currentDate, 'day')
          );

          // Cria um objeto para o dia (com aulas ou vazio)
          const day = {
            date: currentDate.format('YYYY-MM-DD'),
            titles: lessonsForCurrentDate.map(lesson => {
              return `${moment(lesson.start_time, 'HH:mm:ss').format('HH:mm')} à ${moment(lesson.end_time, 'HH:mm:ss').format('HH:mm')} - ${lesson.title}`;
            }),
          };

          allWeekendDays.push(day);
        }

        // Adiciona um dia à data atual
        currentDate.add(1, 'day');
      }

      // Adiciona um dia vazio após o último dia de aula, se a última aula terminar no sábado
      if (lastLessonDate.day() === 6) {
        allWeekendDays.push({
          date: currentDate.format('YYYY-MM-DD'),
          titles: []
        });
      }

      // Remove dias anteriores ao primeiro dia de aula
      this.weekendLessons = allWeekendDays.filter(day => moment(day.date).isSameOrAfter(firstLessonDate, 'day'));

      // Adiciona um dia vazio após o último dia de aula, se a última aula for no sábado
      const lastWeekendDay = this.weekendLessons[this.weekendLessons.length - 1];
      if (lastWeekendDay && moment(lastWeekendDay.date).day() === 6) {
        const nextDay = moment(lastWeekendDay.date).add(1, 'day');
        this.weekendLessons.push({
          date: nextDay.format('YYYY-MM-DD'),
          titles: []
        });
      }

      this.weekendLessons = this.chunkLessons(this.weekendLessons);

      let areAllTitlesEmpty = this.weekendLessons.every(week => {
        return week.every(day => day.titles.length === 0);
      });
      
      if(areAllTitlesEmpty) {
        this.weekendLessons = [];
      }
    },

    // Função para dividir os dias do final de semana em blocos de 2 (Sábado e Domingo)
    chunkLessons(lessons) {
      const chunked = [];
      
      for (let i = 0; i < lessons.length; i += 2) {
        const chunk = lessons.slice(i, i + 2);
        
        // Verifica se o bloco contém pelo menos uma entrada com títulos não vazios
        const hasTitles = chunk.some(day => day.titles.length > 0);
        
        if (hasTitles) {
          chunked.push(chunk);
        }
      }
      
      return chunked;
    },

    getWorkDays() {
      const days = [];
      const currentDate = moment(this.date_start, 'YYYY-MM-DD');
      
      const startOfWeek = currentDate.clone().startOf('week').add(1, 'day');

      while (startOfWeek.isBefore(this.date_end, 'day')) {
        this.nameWorkDays.forEach((dayName, index) => {
          const date = startOfWeek.clone().add(index, 'days');
          
          const lessonsForCurrentDate = this.item.lessons.filter(lesson =>
            moment(lesson.date, 'YYYY-MM-DD').isSame(date, 'day')
          );

          const day = {
            date: date.format('YYYY-MM-DD'),
            dayOfWeek: dayName,
            titles: lessonsForCurrentDate.map(lesson => {
              return `${moment(lesson.start_time, 'HH:mm:ss').format('HH:mm')} à ${moment(lesson.end_time, 'HH:mm:ss').format('HH:mm')} - ${lesson.title}`
            }),
          };

          days.push(day);
        });

        startOfWeek.add(1, 'week');
      }

      const chunkedDays = [];
      for (let i = 0; i < days.length; i += 5) {
        chunkedDays.push(days.slice(i, i + 5));
      }

      this.daysWork = chunkedDays;

      let areAllTitlesEmpty = this.daysWork.every(week => {
        return week.every(day => day.titles.length === 0);
      });
      
      if(areAllTitlesEmpty) {
        this.daysWork = [];
      }
    },

    async getData() {
      await this.$store.dispatch("Grid/fetchView", this.classUuid).then((grid) => {
        this.item = grid;

        if (this.item.postponements.length) {
          this.date_start = moment(this.item.postponements[this.item.postponements.length - 1].date, 'YYYY-MM-DD').format('YYYY-MM-DD')
          this.date_end = moment(this.item.lessons[this.item.lessons.length - 1].date, 'YYYY-MM-DD').format('YYYY-MM-DD')
          this.getWorkDays()
          this.getWeekendLessons();
        }

        if (this.item.modality.title === 'Live' && this.userData.school.name === 'Degrau Cultural') {
          this.item.unit = {
            title: 'Degrau Live'
          }
        } else if (this.item.modality.title === 'Live' && this.userData.school.name === 'Central de Concursos') {
          this.item.unit = {
            title: 'Central Live'
          }
        }

        this.lessons = this.item.lessons.reduce((acc, lesson) => {
          let { date, title, start_time, end_time, workload, full_name, status, category } = lesson;
          date = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')

          if (!acc[date]) {
            acc[date] = [];
          }

          acc[date].push({
            title: title,
            time: `${moment(start_time, 'HH:mm:ss').format('HH:mm')} às ${moment(end_time, 'HH:mm:ss').format('HH:mm')}`,
            workload: workload,
            teacher: full_name,
            date: date,
            status: status,
            category: category,
          });

          this.item.end_date = date

          return acc;
        }, {});

      })
    },
    formatDateUSA(dt, fmat) {
      return moment(dt, 'YYYY-MM-DD').format(`${fmat}`)
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  .table {
    td {
      font-size: 0.9rem;
    }

    th {
      padding: 5px 10px !important;
    }
  }

  .table-days {
    .bg-success {
      background-color: #57cc99 !important;
    }

    .text-success {
      color: #57cc99 !important
    }

    .bg-danger {
      background-color: #f17374 !important
    }

    .text-danger {
      color: #f17374 !important
    }

    @media print {
      .bg-success {
        background-color: #000 !important;
      }

      .text-success {
        color: #000 !important
      }

      .bg-danger {
        background-color: #000 !important
      }

      .text-danger {
        color: #000 !important
      }
    }

    thead {
      tr {
        >th {
          padding: 10px !important;
          font-size: 11px;

          &:first-child {
            border-radius: 6px 0px 0;
          }

          &:last-child {
            border-radius: 0px 6px 0;
          }
        }
      }
    }

    tbody {
      td {
        font-size: 11px;
        padding: 0px !important;

        .d-cols {
          display: inline-flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          column-gap: 5px;
          width: 100%;

          .fist-col {
            padding-top: 5px;
            padding-bottom: 5px;
            background: #d5d5d5;
            padding: 10px 5px;
            max-width: 50px;
            width: 100%;
            text-align: center;
            border-radius: 3px;
          }

          .last-col {
            padding-top: 5px;
            padding-bottom: 5px;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }

  .table-basic {

    tbody,
    thead {
      color: #000;
    }

    thead {
      tr {
        >th {
          padding: 10px !important;
          font-size: 11px;
          border: solid 1px rgb(121, 121, 121);
          background: #b0b0b0 !important;
        }
      }
    }

    tbody {
      td {
        font-size: 11px;
        border: solid 1px #d1d1d1;
        padding: 5px !important;

      }
    }
  }
}
</style>