var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("h3", { staticClass: "text-center my-4" }, [
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(_vm._s(_vm.item.course.title)),
            ]),
            _c("span", { staticClass: "d-block mt-1" }, [
              _vm._v(" " + _vm._s(_vm.userData.school.name) + " "),
            ]),
          ]),
          _c(
            "b-card",
            { staticClass: "mb-1", attrs: { "no-body": "" } },
            [
              _c(
                "b-card-header",
                [
                  _c("b-card-title", { staticClass: "mb-1" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.item.name) +
                        " - " +
                        _vm._s(_vm.item.modality.title) +
                        " "
                    ),
                  ]),
                  _c(
                    "b-card-sub-title",
                    {
                      staticStyle: {
                        "margin-top": "6px",
                        color: "#707070 !important",
                      },
                    },
                    [
                      _vm._v(" Dias: "),
                      _vm._l(_vm.item.weekdays, function (day, index) {
                        return _c("span", { key: "day-" + index }, [
                          _vm._v(
                            " " + _vm._s(day.title.replace("-Feira", "")) + " "
                          ),
                          index !== _vm.item.weekdays.length - 1
                            ? _c("span", [_vm._v("-")])
                            : _vm._e(),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-card-body",
                { staticClass: "pl-2 pr-2" },
                [
                  _c("b-card-text", [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex",
                        staticStyle: { "column-gap": "30px" },
                      },
                      [
                        _vm.item.unit
                          ? _c("div", {}, [
                              _c("strong", [_vm._v("Unidade: ")]),
                              _vm._v(" " + _vm._s(_vm.item.unit.title) + " "),
                            ])
                          : _vm._e(),
                        _c("div", {}, [
                          _c("strong", [_vm._v("Turno: ")]),
                          _vm._v(" " + _vm._s(_vm.item.shift.title) + " "),
                        ]),
                        _c("div", {}, [
                          _c("strong", [_vm._v("Início: ")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("datePT")(_vm.item.postponements[0].date)
                              ) +
                              " "
                          ),
                        ]),
                        _c("div", {}, [
                          _c("strong", [_vm._v("Término: ")]),
                          _vm._v(" " + _vm._s(_vm.item.end_date) + " "),
                        ]),
                        _c("div", {}, [
                          _c("strong", [_vm._v("Horário: ")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.item.start_time) +
                              " às " +
                              _vm._s(_vm.item.end_time) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm.daysWork.length
                    ? _c("table", { staticClass: "table b-table table-days" }, [
                        _c("thead", [
                          _c(
                            "tr",
                            _vm._l(_vm.nameWorkDays, function (dayName, index) {
                              return _c(
                                "th",
                                {
                                  key: "header-" + index,
                                  staticClass: "text-center",
                                },
                                [_vm._v(" " + _vm._s(dayName) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.daysWork, function (week, weekIndex) {
                            return _c(
                              "tr",
                              { key: "week-" + weekIndex },
                              _vm._l(week, function (day, dayIndex) {
                                return _c("td", { key: "day-" + dayIndex }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-cols",
                                      staticStyle: { margin: "2px 0px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-white fist-col",
                                          class: day.titles.length
                                            ? "bg-success"
                                            : "bg-danger",
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatDateUSA(
                                                  day.date,
                                                  "DD/MM"
                                                )
                                              ) +
                                              " "
                                          ),
                                          day.titles.length > 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "absolute bg-primary",
                                                  staticStyle: {
                                                    "border-radius": "30%",
                                                    width: "17px",
                                                    height: "17px",
                                                    display: "flex",
                                                    "align-content": "center",
                                                    "align-items": "center",
                                                    "justify-content": "center",
                                                    top: "10px",
                                                    position: "absolute",
                                                    right: "-19px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        day.titles.length
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c("div", { staticClass: "last-col" }, [
                                        day.titles.length
                                          ? _c(
                                              "strong",
                                              { staticClass: "text-success" },
                                              _vm._l(
                                                day.titles,
                                                function (lesson, c) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key: "le-" + c,
                                                      staticStyle: {
                                                        width: "100%",
                                                        display: "block",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(lesson) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c(
                                              "strong",
                                              { staticClass: "text-danger" },
                                              [_vm._v(" [Sem aula] ")]
                                            ),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.weekendLessons.length
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    [
                      _c("b-card-title", { staticClass: "mb-0" }, [
                        _vm._v(" Aulas aos finais de semana "),
                      ]),
                    ],
                    1
                  ),
                  _c("b-card-body", [
                    _c("table", { staticClass: "table b-table table-days" }, [
                      _c("thead", [
                        _c(
                          "tr",
                          _vm._l(
                            _vm.nameWeekendDays,
                            function (dayName, index) {
                              return _c(
                                "th",
                                {
                                  key: "header-" + index,
                                  staticClass: "text-center",
                                },
                                [_vm._v(" " + _vm._s(dayName) + " ")]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.weekendLessons, function (week, weekIndex) {
                          return _c(
                            "tr",
                            { key: "week-" + weekIndex },
                            _vm._l(week, function (day, dayIndex) {
                              return _c("td", { key: "day-" + dayIndex }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-cols",
                                    staticStyle: { margin: "2px 0px" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text-white fist-col",
                                        class: day.titles.length
                                          ? "bg-success"
                                          : "bg-danger",
                                        staticStyle: { position: "relative" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatDateUSA(
                                                day.date,
                                                "DD/MM"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "absolute bg-primary",
                                            staticStyle: {
                                              "border-radius": "30%",
                                              width: "17px",
                                              height: "17px",
                                              display: "flex",
                                              "align-content": "center",
                                              "align-items": "center",
                                              "justify-content": "center",
                                              top: "10px",
                                              position: "absolute",
                                              right: "-19px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(day.titles.length) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "last-col" }, [
                                      day.titles.length
                                        ? _c(
                                            "strong",
                                            { staticClass: "text-success" },
                                            _vm._l(
                                              day.titles,
                                              function (lesson, c) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: "le-" + c,
                                                    staticStyle: {
                                                      width: "100%",
                                                      display: "block",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(lesson) + " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c(
                                            "strong",
                                            { staticClass: "text-danger" },
                                            [_vm._v(" [Sem aula] ")]
                                          ),
                                    ]),
                                  ]
                                ),
                              ])
                            }),
                            0
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-card",
            { staticClass: "mb-4", attrs: { "no-body": "" } },
            [
              _c(
                "b-card-header",
                [
                  _c("b-card-title", { staticClass: "mb-0" }, [
                    _vm._v(" Disciplinas e Professores "),
                  ]),
                ],
                1
              ),
              _c("b-card-body", [
                _c("table", { staticClass: "table b-table" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      _vm._l(_vm.fieldsDisciplines, function (field, index) {
                        return _c(
                          "th",
                          {
                            key: "weekf-" + index,
                            staticClass: "table-b-table-default",
                            class: field.tdClass,
                            staticStyle: { padding: "10px !important" },
                          },
                          [_vm._v(" " + _vm._s(field.label) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.item.disciplines, function (row, index) {
                      return _c("tr", [
                        _c("td", [_vm._v(" " + _vm._s(row.title) + " ")]),
                        _c("td", [
                          row.teachers.length
                            ? _c(
                                "span",
                                _vm._l(row.teachers, function (teacher, index) {
                                  return _c(
                                    "span",
                                    { key: "teacher-" + index },
                                    [_vm._v(" " + _vm._s(teacher.name) + " ")]
                                  )
                                }),
                                0
                              )
                            : _c("span", { staticClass: "text-danger" }, [
                                _vm._v("Nenhum"),
                              ]),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("strong", [
                            _vm._v(" " + _vm._s(row.workload) + "h "),
                          ]),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }